.container {
  text-align: center;
  z-index: 1000;
  transform: translateZ(-2px) scale(1.5);
  position: absolute;
  top: 70vh;
  cursor: none;
  transition: transform 0.2s;
}

.link {
  align-items: center;
  padding: 5px;
  text-decoration: none;
  display: block;
  cursor: grabbing;
}

.text {
  color: white;
  display: block;
  text-decoration: none;
  text-shadow: 0 0 5px #000;
  font-size: var(--font-size-small);
}

.arrow {
  border: solid white;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  opacity: 0.6;
  transition: opacity 0.5s;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
}

.container:hover {
  transform: scale(1.5);

  .arrow {
    animation: bounce 0.5s infinite;
    opacity: 1;
  }
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0) rotate(45deg);
  }
  50% {
    transform: translateY(-10px) rotate(45deg);
  }
}
