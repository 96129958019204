.dialog {
  padding: 0 1rem 1rem 1rem;
}
.dialog::backdrop {
  background: purple;
  opacity: 0.3;
}

.dialogForm {
  padding: 0;
  margin: 0;
}
