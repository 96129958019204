@import url("https://fonts.googleapis.com/css2?family=Pixelify+Sans:wght@500&family=Smooch+Sans:wght@300;500&display=swap");
@import "vars.module.css";

:root {
  --primary-color: #282c34;
  --primary-color-alt: orange;

  --secondary-color: ghostwhite;
  --secondary-color-alt: var(--primary-color-alt);
  --secondary-color-highlight: green;
  --font-color-link: var(--secondary-color-alt);
  --font-color-link-hover: yellow;

  --primary-text-color: ghostwhite;
  --primary-text-color-alt: red;
  --primary-bg-color: var(--primary-color);

  --primary-btn-color: var(--primary-color);
  --primary-btn-text-color: var(--secondary-color);

  --secondary-btn-color: var(--secondary-color-alt);
  --secondary-btn-text-color: var(--primary-color);

  --secondary-text-color-alt: var(--secondary-color);

  --font-weight-heavy: 500;
  --font-weight-medium: 500;
  --font-weight-normal: 300;

  --font-family-alt: "Pixelify Sans", Helvetica, Arial, sans-serif;
  --font-family-default: "Smooch Sans", Helvetica, Arial, sans-serif;

  --step--2: clamp(0.7738rem, 1.2695rem + -0.3098vw, 1.2075rem);
  --step--1: clamp(1.0313rem, 1.3255rem + -0.1839vw, 1.2888rem);
  --step-0: clamp(1.375rem, 1.375rem + 0vw, 1.375rem);
  --step-1: clamp(1.4669rem, 1.4146rem + 0.2616vw, 1.8331rem);
  --step-2: clamp(1.5656rem, 1.4403rem + 0.6268vw, 2.4431rem);
  --step-3: clamp(1.67rem, 1.4433rem + 1.1335vw, 3.2569rem);
  --step-4: clamp(1.7825rem, 1.417rem + 1.8277vw, 4.3412rem);
  --step-5: clamp(1.9019rem, 1.3469rem + 2.775vw, 5.7869rem);

  --font-size-tiny: var(--step--2);
  --font-size-small: var(--step--1);
  --font-size-medium: var(--step-0);
  --font-size-emphasis: var(--step-1);
  --font-size-heading: var(--step-3);
  --font-size-large: var(--step-4);
  --font-size-title: var(--step-5);

  --border-width: 0.2rem;
  --border-radius: 0.5rem;
}

@media (prefers-color-scheme: light) {
  :root {
    --primary-color: ghostwhite;
    --secondary-color: #282c34;
  }
}
