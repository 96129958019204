@import "../vars.module.css";

.button {
  font-family: var(--font-family-alt);
  font-weight: 500;
  border: 2px solid var(--secondary-text-color-alt);
  border-radius: 1rem;
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  transition: transform 0.5s;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 0px 1px inset;
  user-select: none;

  &:hover {
    transform: scale(1.04);
  }
}

.button + .button {
  margin-left: 1rem;
}

.primary .dialog {
  color: var(--primary-btn-color);
  background-color: var(--primary-btn-text-color);
}

.secondary {
  color: var(--secondary-btn-text-color);
  background-color: var(--secondary-btn-color);
}

.cta {
  border: 5px solid purple;
  border-radius: 5px;
  width: clamp(100px, 100%, 600px);
  margin: 0 auto;
  padding: 1rem;
  background: var(--secondary-color-alt);
  font-weight: 700;
  font-family: var(--font-family-headings);
  letter-spacing: 0.05rem;
  color: var(--primary-color);
  text-decoration: none;
  transition: transform 0.1s;
  display: block;

  &:hover {
    text-decoration: none;
    transform: scale(1.1);
    color: var(--primary-text-color);
  }
}

.small {
  padding: 0.5rem;
  font-size: var(--font-size-medium);
  font-weight: var(--font-weight-normal);
}

.medium {
  padding: 1rem 2rem;
  font-size: var(--font-size-emphasis);
}

.large {
  padding: 1.5rem 3rem;
  font-size: var(--font-size-large);
}
