@import "modern-normalize";
@import "vars.module.css";

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: var(--font-family-default);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--primary-color);
  color: var(--primary-text-color);
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-medium);
}

/* Remove scrollbars */
::-webkit-scrollbar {
  width: 0;
  height: 0;
  background: transparent;
}

h1,
h2,
h3,
h4 {
  user-select: none;
  font-family: var(--font-family-alt);
  margin-bottom: clamp(0.8rem, 3vmin, 1.5rem);
  line-height: 1;
}
p {
  user-select: none;
  margin-bottom: clamp(0.8rem, 3vmin, 1.2rem);
  letter-spacing: 0.05rem;
  font-size: var(--font-size-medium);
  line-height: clamp(1rem, 5vmin, 2rem);
}

h1 {
  font-size: var(--font-size-large);
  letter-spacing: 0.2rem;
  color: var(--secondary-color);
  font-weight: var(--font-weight-heavy);
  line-height: 80%;
}
h2 {
  font-size: var(--font-size-heading);
  color: var(--secondary-color-highlight);
  font-weight: var(--font-weight-medium);
}
h3 {
  font-size: var(--font-size-heading);
  color: var(--secondary-color-alt);
  font-weight: var(--font-weight-medium);
}
h4 {
  font-family: var(--font-size-heading);
  font-size: var-(--font-size-heading);
  color: var(--secondary-color);
  font-weight: var(--font-weight-medium);
}
p a {
  letter-spacing: 0.05rem;
  color: var(--secondary-color-alt);
  text-decoration: none;
  display: inline-block;
  transition: transform 0.1s;

  &:hover {
    text-decoration: underline;
    transform: scale(1.1);
    color: var(--font-color-link-hover);
  }
}

button,
input,
select,
section,
main,
nav {
  font-size: var(--font-size-medium);
}

a,
button {
  user-select: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

*:target {
  border: 2px solid orange;
  border-radius: 1rem;
  opacity: 0.5;
  background-color: gold;
  padding: 1rem;
  color: purple;
}
