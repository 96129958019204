.container {
  position: relative;
  padding: 1rem;
  max-width: 100vmax;
}

.scroller {
  padding: 1rem 3rem;
  outline: 5px solid hotpink;
  display: grid;
  gap: 1rem;
  grid-auto-flow: column;
  grid-auto-columns: clamp(240px, 30%, 300px);
  overflow: scroll;
  scroll-snap-type: x mandatory;
  scroll-padding: 1rem;
  margin-bottom: 2rem;
}

/* Remove scrollbars */
.scroller::-webkit-scrollbar {
  width: 0;
  height: 0;
  background: transparent;
}
