@import "../global.css";

body {
  margin: 0;
}

.paralaxContext {
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  perspective: 10px;
  background: #fff;
}

.header {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  transform-style: preserve-3d;
  z-index: -1;
}

.background {
  transform: translateZ(-10px) scale(2);
}

.foreground {
  transform: translateZ(-5px) scale(1.5);
}

.background,
.foreground {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.title {
  font-size: var(--font-size-title);
  margin: 0;
  color: #fff;
  text-shadow: 0 0 5px #000;
}

.section {
  padding: clamp(0.5rem, 3vw, 1rem) clamp(1rem, 5vw, 3rem);
  background-color: #333;
  color: #fff;
  z-index: 100;
  transform: translateZ(0);
}
